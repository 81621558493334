export const saveCsv = ({ document }: Window, csv: string, filename: string) => {
  const link = document.createElement('a');
  const blob = new Blob([ csv ], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
